<template>
  <div id="hotGame" class="original">
    <div class="hotgame_box max-width">
      <div class="hotgame_title">热门游戏</div>
      <div class="hotgame_content">
        <ul
          class="hotgame_list hand"
          v-for="(item, index) in hotGameList"
          :key="index"
          @click="download(item.gw2)"
        >
          <li class="hotgame_li">
            <div class="list_img" @click="">
              <!-- <img src="../../assets/image/pc/hotGame/1.jpg" alt /> -->
              <a  >
                <img :src="item.img" alt />
              </a>
              <!-- <div class="cd_box">
              <div class="cord_img">二维码</div>
              <div class="dload_box">
                <div class="title_box">{{ item.head }}</div>
                <div class="dl_img">
                  <img src="../../assets/image/icon/21.png" alt />
                </div>
                <div class="dl_text">立即下载</div>
              </div>
            </div> -->
            </div>
            <div class="list_content">
              <div class="title_box">{{ item.head }}</div>
              <div class="descibe_box">
                <div class="version" style="padding-left: 0.04rem">
                  版本：{{ item.version }}
                </div>
                <div class="newOpen" style="padding-left: 0.03rem">
                  上线时间：{{ item.time }}
                </div>
                <div class="timeOpen" style="padding-left: 0.03rem">
                  开服时间：{{ item.newOpen }}
                </div>
              </div>
              <div class="gw">
                <a
                  style="padding-left: 0.01rem"
                  >官网：{{ item.gw }}</a
                >
              </div>
            </div>
          </li>
        </ul>
        <div class="hotgame_list jqqd">敬请期待...</div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadA } from "@/utils/download";
export default {
  data() {
    return {
      currentIndex: -1,
      hotGameList: [
        {
          img: require("@/assets/image/pc/hotGame/mhqn.jpg"),
          head: "《梦幻千年》",
          version: "二层版本",
          time: "2024.7.22",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/bxqn.png"),
          head: "《冰雪千年》",
          version: "独家版本",
          time: "2024.6.29",
          newOpen: "2024.6.29",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/jxq.png"),
          head: "《剑侠情》",
          version: "一层版本",
          time: "2024.7.18",
          newOpen: "2024.7.26",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/qxz.png"),
          head: "《群侠传》",
          version: "一层版本",
          time: "2024.8.7",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/jyqn.png"),
          head: "《九妖千年》",
          version: "高倍怀旧版本",
          time: "",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/ltzj.png"),
          head: "《戮天之剑》",
          version: "二层版本",
          time: "2024.5.10",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/rx.png"),
          head: "《热血Online》",
          version: "一层版本",
          time: "2024.6.7",
          newOpen: "2024.6.12",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/jjgc.png"),
          head: "《精绝古城》",
          version: "绿色版",
          time: "2024.7.16",
          newOpen: "2024.7.20",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/wdqn.png"),
          head: "《武动千年》",
          version: "二层版本",
          time: "2024.7.10",
          newOpen: "2024.7.13",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/qnwc.png"),
          head: "《千年王朝》",
          version: "一层版本",
          time: "2024.7.6",
          newOpen: "",
          gw: "",
          gw2: "",
        },

        {
          img: require("@/assets/image/pc/hotGame/yyjh.png"),
          head: "《烟雨江湖》",
          version: "一层版本",
          time: "2024.7.20",
          newOpen: "",
          gw: "",
          gw2: "",
        },

        // {
        //   // img: '/assets/image/pc/hotGame/1.jpg',
        //   img: require("@/assets/image/pc/hotGame/1.jpg"),
        //   head: "《干年盛世绿色版》",
        //   version: "二层版本",
        //   newOpen: "2023.10.13",
        //   time: "2023.7.22",
        //   gw: "",
        //   gw2: "",
        // },

        {
          img: require("@/assets/image/pc/hotGame/3.jpg"),
          head: "《灵武世界》",
          version: "一层版本",
          newOpen: "2023.9.28",
          time: "2023.9.28",
          gw: "",
          gw2: "",
        },

        // {
        //   img: require("@/assets/image/pc/hotGame/5.jpg"),
        //   head: "《创世千年》",
        //   version: "二层版本",
        //   time:"2023.10.28",
        //   newOpen: "2023.11.2",
        //   gw: "",
        //   gw2: "",
        // },

        // {
        //   img: require("@/assets/image/pc/hotGame/6.jpg"),
        //   head: "《千年盛世之龙麟决》",
        //   version: "二层版本",
        //   time: "2023.12.8",
        //   newOpen: "2023.12.10",
        //   gw: "",
        //   gw2: "",
        // },
        {
          img: require("@/assets/image/pc/hotGame/7.jpg"),
          head: "《千年盛世之极纯千年》",
          version: "一层版本",
          time: "2023.12.1",
          newOpen: "2023.12.12",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/fs.jpg"),
          head: "《封神千年》",
          version: "一层版本",
          time: "2023.11.2",
          newOpen: "2023.11.6",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/jj.jpg"),
          head: "《九剑魔龙传》",
          version: "二层版本",
          time: "2023.10.27",
          newOpen: "2023.11.3",
          gw: "",
          gw2: "",
        },
        // {
        //   img: require("@/assets/image/pc/hotGame/zx.jpg"),
        //   head: "《再续千缘》",
        //   version: "一层版本",
        //   time: "2023.11.5",
        //   newOpen: "",
        //   gw: "",
        //   gw2: "",
        // },
        {
          img: require("@/assets/image/pc/hotGame/fg.jpg"),
          head: "《复古千年》",
          version: "二层版本",
          time: "2023.11.9",
          newOpen: "2023.11.17",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/fyzq.jpg"),
          head: "《风云再起》",
          version: "一层版本",
          time: "2023.11.24",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/qngl.jpg"),
          head: "《千年归来》",
          version: "武功全自创版",
          time: "2023.11.29",
          newOpen: "2023.12.29",
          gw: "",
          gw2: "",
        },
        // {
        //   img: require("@/assets/image/pc/hotGame/syqn.png"),
        //   head: "《神游千年》",
        //   version: "二层版本",
        //   time: "",
        //   newOpen: "2023.12.22",
        //   gw: "",
        //   gw2: "",
        // },
        // {
        //   img: require("@/assets/image/pc/hotGame/syqn.jpg"),
        //   head: "《太极之章》",
        //   version: "二层版本",
        //   time:'2023.12.2',
        //   newOpen: "2023.12.10",
        //   gw: "",
        //   gw2: "",
        // },
        // {
        //   img: require("@/assets/image/pc/hotGame/zzjz.jpg"),
        //   head: "《征战九州版》",
        //   version: "二层版本",
        //   time: "2023.12.3",
        //   newOpen: "2023.12.15",
        //   gw: "",
        //   gw2: "",
        // },
        {
          img: require("@/assets/image/pc/hotGame/smjs.png"),
          head: "《水墨江山》",
          version: "一层版本",
          time: "2024.1.21",
          newOpen: "2024.1.27",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/jyrg.png"),
          head: "《剑雨如歌》",
          version: "武功全自创版",
          time: "",
          newOpen: "2024.1.30",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/xzd.jpg"),
          head: "《侠众道》",
          version: "二层版本",
          time: "2023.11.17",
          newOpen: "2024.2.1",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/szsd.png"),
          head: "《神州三端》",
          version: "一层版本",
          time: "",
          newOpen: "2024.2.2",
          gw: "",
          gw2: "",
        },
        // {
        //   img: require("@/assets/image/pc/hotGame/dhqn.jpg"),
        //   head: "《大话千年》",
        //   version: "二层版本",
        //   time: "",
        //   newOpen: "2024.1.27",
        //   gw: "",
        //   gw2: "",
        // },
        {
          img: require("@/assets/image/pc/hotGame/lhcfjz.png"),
          head: "《离火重返激战》",
          version: "二层版本",
          time: "2024.4.22",
          newOpen: "2024.4.27",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/xkxqn.png"),
          head: "《侠客行千年》",
          version: "三层版本",
          time: "",
          newOpen: "2024.3.14",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/dxts.png"),
          head: "《大侠坛说》",
          version: "一层版本",
          time: "2024.4.1",
          newOpen: "2024.4.6",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/jyssb.png"),
          head: "《九妖盛世版》",
          version: "二层版本",
          time: "2024.4.20",
          newOpen: "2024.4.30",
          gw: "",
          gw2: "",
        },

        {
          img: require("@/assets/image/pc/hotGame/zzbj.png"),
          head: "《诸子百家之千年武侠》",
          version: "二层版本",
          time: "2024.5.6",
          newOpen: "2024.5.12",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/lhqn.png"),
          head: "《问剑江湖》",
          version: "一层版本",
          time: "2024.4.26",
          newOpen: "2024.5.1",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/xss.png"),
          head: "《新盛世》",
          version: "二层版本",
          time: "",
          newOpen: "",
          gw: "",
          gw2: "",
        },
      ],
    };
  },
  created() {},
  mounted() {
    // this.init()
  },
  watch: {},
  methods: {
    download(url){
      return
      downloadA(url)
    },
    downloadA,
    showbox(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.hand{
  cursor: pointer;
}
.max-width {
  max-width: 1920px;
  margin: 0 auto;
}
.original {
  height: auto;
  position: relative;
  z-index: 2;
  background-color: #0c0905;
  padding-bottom: 0.2rem;
}
a {
  color: #949494;
}
.nav_pop_enter {
  animation-name: slideDown;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  transform-origin: center top;
}
/* 定义一个动画 */
@keyframes slideDown {
  from {
    transform: scaleY(0);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}
.nav_pop_leave {
  animation-name: slideDownS;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  transform-origin: center top;
}
/* 定义一个动画 */
@keyframes slideDownS {
  from {
    transform: scaleY(1);
    opacity: 1;
  }
  to {
    transform: scaleY(0);
    opacity: 0;
  }
}
.hotgame_box {
  height: 100%;

  background: url(../../assets/image/pc/bg/142.jpg) no-repeat;
  // background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .hotgame_title {
    width: 100% /* 160/192 */;
    height: 0.916667rem /* 176/192 */;
    text-align: center;
    line-height: 1.1rem /* 176/192 */;
    // background: palegreen;
    font-size: 0.208333rem /* 40/192 */;

    color: #f8f3e9;
    font-weight: bold;
  }
  .hotgame_content {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    width: 6.3125rem /* 1212/192 */;
    // height: 3.56875rem /* 666/192 */;
    // background: palevioletred;
    box-sizing: border-box;
    flex-wrap: wrap;
    li {
      position: relative;
      z-index: 1;
      width: 1.5rem /* 288/192 */;
      height: 1.682292rem /* 323/192 */;
      margin-left: 0.078rem /* 18/192 */;
      margin-bottom: 0.078rem;
      background: #ffffff;
      border-radius: 0.052083rem /* 10/192 */;
      opacity: 1;
      box-sizing: border-box;

      .list_img {
        width: 1.5rem /* 288/192 */;
        height: 0.8125rem /* 156/192 */;
        margin-bottom: 0.104167rem /* 20/192 */;
        border-radius: 0.052083rem /* 10/192 */ 0.052083rem /* 10/192 */ 0 0;
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 0.052083rem /* 10/192 */ 0.052083rem /* 10/192 */ 0 0;
        }
      }
      .list_img:hover {
        .cd_box {
          opacity: 1;
          height: 0.8125rem;
        }
      }
      .cd_box {
        overflow: hidden;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 1.5rem /* 288/192 */;
        height: 0;
        transition: 0.4s;
        /* height: 0.8125rem; */
        box-sizing: border-box;
        margin-bottom: 0.104167rem;
        border-radius: 0.052083rem /* 10/192 */ 0.052083rem /* 10/192 */ 0rem
          /* 0/192 */ 0rem;
        background: rgba(204, 83, 65, 0.85);
        display: flex;
        align-items: center;
        justify-content: center;
        /* animation-name: cd_avtS;
        animation-duration: 0.2s;
        animation-timing-function: ease-in-out;
        transform-origin: center top; */
        .cord_img {
          width: 0.505208rem /* 97/192 */;
          height: 0.505208rem /* 97/192 */;
          background: #ffffff;
        }
        .dload_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .title_box {
            font-size: 0.083333rem /* 16/192 */;
            margin-bottom: 0.088542rem /* 17/192 */;
            color: #f6f5f4;
            font-weight: bold;
          }
          .dl_img {
            width: 0.177083rem /* 34/192 */;
            height: 0.166667rem /* 32/192 */;
            margin-bottom: 0.083333rem /* 16/192 */;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .dl_text {
            color: #f6f5f4;
            font-weight: bold;
            font-size: 0.083333rem /* 16/192 */;
          }
        }
      }
      .cd_avt {
        transition: all 0.6s ease-in-out;
        transform: translateY(0);
      }
      /* 定义一个动画 */
      @keyframes cd_avtS {
        from {
          transform: translateY(-0.8125rem);
          opacity: 0;
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
      .list_content {
        margin-left: 0.067708rem /* 13/192 */;
        .title_box {
          font-weight: bold;
          color: #393333;
          font-size: 0.083333rem /* 16/192 */;
          margin-bottom: 0.04167rem /* 20/192 */;
        }
        .descibe_box {
          height: 0.25rem;
          line-height: 0.12rem;
          color: #231c0e;
          font-size: 0.072917rem /* 14/192 */;
          margin-bottom: 0.167rem /* 20/192 */;
          // padding-left: 0.04rem;
        }
        .gw {
          font-size: 0.072917rem /* 14/192 */;
          color: #949494;
          height: 0.165625rem /* 51/192 */;
          border-top: 0.005208rem /* 1/192 */ solid #ececec;
          line-height: 0.1625rem /* 51/192 */;
          padding-left: 0.03rem;
        }
      }
    }
    .jqqd {
      width: 1.5rem /* 288/192 */;
      height: 1.682292rem /* 323/192 */;
      text-align: center;
      line-height: 1.682292rem;
      font-size: 0.09375rem /* 18/192 */;
      color: #f6f5f4;
    }
  }
}
</style>
