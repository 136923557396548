import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)
Vue.config.productionTip = false
import 'amfe-flexible';
import "./utils/flexible"
import "@/assets/css/swiper_animate.min.css"
import "@/assets/css/swiper-bundle.min.css"
const Swiper = require('@/assets/js/swiper-bundle.min.js')
// const Jquery = require('@/assets/js/jquery.min.js')
// 正式环境清除所有console.log
if (process.env.NODE_ENV !== 'development') {
  if (window) {
    window.console.log = function () {};
  }
}
window.Swiper = Swiper
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


