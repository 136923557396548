export const Androiddownload = "https://downloads.qn1000a.com/apk/qnbox/千年游戏盒子_v1.4.6.apk"
export const Androidbag = "com.hygame.qnboxaz3://"
export const iosbag = "com.qnhz.yxhk4://"
export const iosdownload = "https://apps.apple.com/cn/app/%E5%8D%83%E6%B8%B8%E7%A4%BE%E5%8C%BA/id6475273179"
export const iosdownloadpc = "https://apps.apple.com/cn/app/%E5%8D%83%E6%B8%B8%E7%A4%BE%E5%8C%BA/id6475273179"
export const pcdownload = "https://downloads.qn1000a.com/box/pc/online/1.1.0.0/%E5%8D%83%E5%B9%B4%E6%B8%B8%E6%88%8F%E7%9B%92%E5%AD%90_1.1.0.0.exe"
export const kfUrl = "https://work.weixin.qq.com/kfid/kfcca34e1bc2b30e49f" //微信客服地址

/**
 * 
 * @param {*} target   默认_blank
 * _blank在新窗口或选项卡中打开链接文档,_self 在与点击相同的框架中打开链接的文档（默认）。
 */
export function downloadA(url, target = '_blank') {
    if(!url) return
    const dowa = document.createElement('a');
    // dowa.href = this.navbannerList[index].url
    dowa.href = url
    dowa.target = target
    document.body.appendChild(dowa);
    dowa.click();
    document.body.removeChild(dowa);
}
export function callApp(schema, timeOut) {
    let timer, start = new Date().getTime();
    let myPromise = new Promise((resolve, reject) => {
        window.location.href = schema;
        window.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                clearTimeout(timer);
                resolve();
            };
        });
        timer = setTimeout(() => {
            let end = new Date().getTime();
            if (end - start > timeOut + 1000) {
                clearTimeout(timer);
                resolve();
            } else {
                reject();
            };
        }, timeOut);
    })
    return myPromise
}
export function location(url) {
    window.location.href = url;
}