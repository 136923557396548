<template>
  <div id="navigation">
    <div class="navigation_box">
      <div class="icon_box">
        <img src="../../assets/image/icon/logo.png" alt />
      </div>
      <div class="nav_box">
        <ul v-for="(item, index) in navList" :key="index" class="nav">
          <li
            :class="index == indexRed ? 'nav_click' : 'nav_item'"
            @click="cNav(index)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
      <a href="http://www.qn1000a.com/#/" target="_blank">
        <div class="btn_box">千年游戏引擎官网</div>
      </a>
      <!-- <a href="https://www.qn2024.com/" target="_blank">
        <div class="btn2_box"></div>
      </a> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navRed: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {
      indexRed: 0,
      scrollShow: false,
      navList: [
        {
          title: "首页",
          show: true,
          href: "#bannerView",
        },
        {
          title: "资讯中心",
          show: true,
          href: "#newCenter",
        },
        // {
        // 	title: "热门游戏",
        // 	show: true,
        // 	href: "#hotGame",
        // },
        {
          title: "新游推荐",
          show: true,
          href: "#newGame",
        },
        // {
        // 	title: "游戏排行",
        // 	show: true,
        // 	href: "#rankGame",
        // },
        {
          title: "商务合作",
          show: true,
          href: "#aboutUs",
        },
        {
          title: "下载盒子",
          show: true,
          href: "#downloadGame",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  watch: {
    navRed: {
      handler(val) {
        this.indexRed = val;
        if (val == 2) {
          this.indexRed = 9;
          // 9是代表不存在这个页面模块  那么navbar就不会变红色，传进来的是页面模块的位置
        } else if (val == 3) {
          this.indexRed = 2;
        } else if (val == 4) {
          this.indexRed = 9;
        } else if (val == 5) {
          this.indexRed = 3;
        } else if (val == 6) {
          this.indexRed = 4;
        }
      },
    },
  },
  methods: {
    cNav(index) {
      this.indexRed = index;
      let id = this.navList[index].href;
      this.$emit("navChang", id);
    },
  },
};
</script>
<style></style>
<style lang="scss" scoped>
#navigation {
  background: #000;
  width: 100%;
  height: 0.4rem /* 90/192 */;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;

  .navigation_box {
    width: 6rem;
    height: 0.46875rem /* 90/192 */;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon_box {
    width: 0.234375rem /* 45/192 */;
    height: 0.234375rem /* 45/192 */;
    margin-left: -1rem /* 65/192 */;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .nav_box {
    // width: 100%;
    // height: 0.46875rem /* 90/192 */;
    display: flex;
    align-items: center;
    justify-content: center;

    .nav {
      // width: 100%;
      // height: 0.46875rem /* 90/192 */;
      // background: palevioletred;
      margin-left: 0.338542rem /* 65/192 */;

      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.104167rem /* 20/192 */;
      color: #ffffff;

      .nav_item:hover {
        color: red;
        cursor: pointer;
      }
    }
  }
  .btn_box {
    width: 0.9375rem /* 180/192 */;
    height: 0.239583rem /* 46/192 */;
    background: url(../../assets/image/pc/bg/1.gif) no-repeat;
    background-size: contain;
    color: #fff;
    font-size: 0.083333rem /* 16/192 */;
    text-align: center;
    line-height: 0.23rem;
    margin-left: 0.4rem /* 140/192 */;
    cursor: pointer;
  }
  .btn2_box {
    width: 1.21rem /* 180/192 */;
    height: 0.25rem /* 46/192 */;
    background: url(../../assets/image/pc/bg/gf.png) no-repeat;
    background-size: contain;
    color: #fff;
    font-size: 0.083333rem /* 16/192 */;
    text-align: center;
    line-height: 0.23rem;
    margin-left: 0.15rem /* 140/192 */;
    margin-bottom: 0.03rem;
    cursor: pointer;
  }
  .nav_click {
    color: red;
    cursor: pointer;
  }
}

// #navigation:hover {
//
// }

// banner
</style>
